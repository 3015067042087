import Head from 'next/head';
import NextNProgress from 'nextjs-progressbar';
import { appWithTranslation } from 'next-i18next';
import '../index.scss';
import { useRouter } from 'next/dist/client/router';
import { useEffect, useRef } from 'react';
import * as mtm from '../utils/mtm';

const usePreviousRoute = () => {
  const { asPath } = useRouter();

  const ref = useRef(null);

  useEffect(() => {
    ref.current = asPath;
  }, [asPath]);

  return ref.current;
};

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const prevRoute = usePreviousRoute();

  useEffect(() => {
    const handleRouteChange = () => {
      mtm.pageView(
        prevRoute,
        pageProps?.isAuthenticated,
        pageProps?.user?.role,
        0
      );
    };
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [
    router.events,
    prevRoute,
    pageProps?.isAuthenticated,
    pageProps?.user?.role,
  ]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <NextNProgress />
      <Component {...pageProps} prevRoute={prevRoute} />
    </>
  );
}

export default appWithTranslation(MyApp);

const DEBUG = false;

export const mtmPush = (data) => {
  if (typeof window === 'undefined') {
    return;
  }

  window._mtm = window._mtm || [];

  if (DEBUG) {
    console.log(data);
  }

  window._mtm.push(data);
};

export const pageView = (url, isLoggedIn, userType, activities) => {
  // Don't trigger pageview on every filter change
  if (url && url.indexOf('boka-tid/?' > -1)) {
    return;
  }
  window._paq = window._paq || [];
  window._mtm = window._mtm || [];
  if (url) {
    window._paq.push(['setReferrerUrl', url]);
  }
  window._paq.push(['deleteCustomDimension', 1]);
  window._paq.push(['deleteCustomVariables', 'page']);
  window._paq.push(['trackPageView']);
  window._paq.push(['enableLinkTracking']);
  mtmPush({
    event: 'mtm.PageView',
    login: isLoggedIn,
    userType,
    activities,
    url,
  });
};

export const event = (event, data = {}) => {
  window._mtm = window._mtm || [];
  window._mtm.push({ event, ...data });
};

export const ctaButtonClick = (ctaText, ctaFormat) => {
  mtmPush({ event: 'ctaButtonClick', ctaText, ctaFormat });
};

export const faqButtonClick = (faqQuestion, faqCategory) => {
  mtmPush({ event: 'faqButtonClick', faqQuestion, faqCategory });
};

export const createAccount = (gender, occupation) => {
  mtmPush({ event: 'createAccount', gender, occupation });
};

export const login = (userType) => {
  mtmPush({ event: 'login', userType });
};

export const invoiceExport = () => {
  mtmPush({ event: 'invoiceExport' });
};

export const statisticsExport = () => {
  mtmPush({ event: 'statisticsExport' });
};

export const bookAdvisor = (
  activityType,
  industry,
  subject,
  place,
  phase,
  language,
  firstBooking
) => {
  mtmPush({
    event: 'bookAdvisor',
    activityType,
    industry,
    subject,
    place,
    phase,
    language,
    firstBooking,
  });
};

export const filterActivity = (data) => {
  mtmPush({ event: 'filterActivity', ...data });
};

export const createActivity = (data) => {
  mtmPush({ event: 'createActivity', ...data });
};

export const listSort = (data) => {
  mtmPush({ event: 'listSort', ...data });
};

export const listSearch = (data) => {
  mtmPush({ event: 'listSearch', ...data });
};

export const acceptAdvisor = () => {
  mtmPush({ event: 'acceptAdvisor' });
};
